<template>
  <b-card-code>
    <div v-if="$can('assign','4th subject')" class="custom-search d-flex mb-2">
      <router-link :to="{path:'/assign/student/fourth/subject'}">
        <b-button variant="outline-primary">
          {{ $t('Add New') }}
        </b-button>
      </router-link>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <b-col md="3">
        <b-card-text>
          <span>Select Class </span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            name="class"
            rules=""
        >
          <select
              name="classes_id"
              v-model="classes_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(cls, key) in classes"
                :value="cls.id"
                :key="key"
            >
              {{ cls.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="2">
        <b-card-text>
          <span>Select Version</span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            name="version"
            rules=""
        >
          <select
              name="version_id"
              v-model="version_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(version, key) in versions"
                :value="version.id"
                :key="key"
            >
              {{ version.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
      <b-col md="3">
        <b-card-text>
          <span>Select Student Group</span>
        </b-card-text>
        <validation-provider
            #default="{ errors }"
            name="student group"
            rules=""
        >
          <select
              name="student_group_id"
              v-model="student_group_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              class="form-control"
          >
            <option :value="null">Select One</option>
            <option
                v-for="(sgrp, key) in student_groups"
                :value="sgrp.id"
                :key="key"
            >
              {{ sgrp.name }}
            </option>
          </select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-col>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
      <div class="custom-search d-flex justify-content-start">
          <vue-excel-xlsx
              :data="getData"
              :columns="columns10"
              :filename="'filename'"
              :sheetname="'subjects'"
          >
              <b>Excel</b>
          </vue-excel-xlsx>
          <vue-json-to-csv :json-data="getData"
                           :csv-title="'My_CSV'"
          >
              <button>
                  <b>CSV</b>
              </button>
          </vue-json-to-csv>
          <button @click="pdfGenerate()">
              <b>PDF</b>
          </button>
      </div>
    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="forth_subjects"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <span v-else-if="props.column.field === 'student_id'">
          {{ cadetIdByID(props.row.student_id) }}
        </span>
        <span v-else-if="props.column.field === 'name'">
          {{ studentNameByID(props.row.student_id) }}
        </span>
        <span v-else-if="props.column.field === 'classes_id'">
          {{ classByID(props.row.classes_id) }}
        </span>
        <span v-else-if="props.column.field === 'version_id'">
          {{ versionByID(props.row.version_id) }}
        </span>
        <span v-else-if="props.column.field === 'wing_id'">
          {{ wingByID(props.row.wing_id) }}
        </span>
        <span v-else-if="props.column.field === 'student_group_id'">
          {{ student_groupByID(props.row.student_group_id) }}
        </span>
        <span v-else-if="props.column.field === 'section_id'">
          {{ sectionById(props.row.section_id) }}
        </span>
        <span v-else-if="props.column.field === 'subject_id'">
          {{ subjectByID(props.row.subject_id) }}
        </span>
        <span v-else-if="props.column.field === 'academic_year_id'">
          {{ academic_yearByID(props.row.academic_year_id) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
              <b-button v-if="$can('remove','4th subject')" v-ripple.400="'rgba(113, 102, 240, 0.15)'" @click="remove(props.row.id)"
                        v-b-modal.modal-login variant="danger">
                  <feather-icon class="text-body " icon="TrashIcon" size="15" />
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20','50','100','500']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,
    BCol,BCardText,
} from 'bootstrap-vue'
import { ValidationProvider, } from 'vee-validate'

import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
export default {
  name:'StudentFourthSubject',
  components: {
    BCardCode,
    VueGoodTable,
    BCol,BCardText,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,ValidationProvider, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      pageLength: 10,
      dir: false,
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Student Number',
          field: 'student_id',
        },
        {
          label: 'Class',
          field: 'classes_id',
        },
        {
          label: 'Version',
          field: 'version_id',
        },
        {
          label: 'Institution',
          field: 'wing_id',
        },
        {
          label: 'Student Group',
          field: 'student_group_id',
        },
        {
          label: 'Subject',
          field: 'subject_id',
        },
        {
          label: 'Academic Year',
          field: 'academic_year_id',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      classes_id:null,
      version_id:null,
      student_group_id:null,
        columns10: [
            {
                label: 'Student Id',
                field: 'StudentId',
            },
            {
                label: 'Name',
                field: 'Name',
            },
            {
                label: 'Class',
                field: 'Class',
            },
            {
                label: 'Version',
                field: 'Version',
            },{
                label: 'Wing',
                field: 'Wing',
            },
            {
                label: 'Student Group',
                field: 'StudentGroup',
            },
            {
                label: 'Academic Year',
                field: 'AcademicYear',
            },
        ],
    }
  },
  methods:{
    async remove(id){
      await this.$swal({
        title: 'Are you sure?',
        text: "You want to remove!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.delete(`/remove/assigned/student/fourth/subject${id}`).then((response) => {
            if (response.data.status === 'success') {
              this.$toaster.success(response.data.message);
              this.$store.dispatch('GET_ALL_FORTH_SUBJECT');
            } else {
              this.$toaster.error(response.data.message);
            }
          }).catch((error) => {
            if (error.response.status == 422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            } else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
      pdfGenerate(){
          /*only change able*/
          let title="Student Fourth Subject Manage";
          let clm=['StudentId','Name','Class','Version', 'Wing','StudentGroup', 'AcademicYear'];
          /*change able end*/
          let data =new FormData();
          data.append('title',title);
          data.append('columns',JSON.stringify(clm));
          data.append('data',JSON.stringify(this.getData));
          apiCall.post('/get/common/table/export/pdf',data,{responseType: 'blob'}).then((response)=>{
              const content = response.headers['content-type'];
              download(response.data,'subject.pdf', content)
          }).catch((error) => {
              this.$toaster.error(error.response.data.message);
          });
      },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    forth_subjects(){
      let sbs=this.$store.getters.forth_subjects;
      if(this.classes_id) sbs= sbs.filter(item =>item.classes_id===this.classes_id);
      if(this.version_id) sbs= sbs.filter(item =>item.version_id===this.version_id);
      if(this.student_group_id) sbs= sbs.filter(item =>item.student_group_id===this.student_group_id);
      return sbs;
    },
    ...mapGetters(['classes','classByID','versions','versionByID','student_groups','students','studentIdByID', 'cadetIdByID',
    'studentNameByID','shifts','shiftByID','wings','wingByID','sections','sectionById','subjects',
    'subjectByID','student_groups','student_groupByID','academic_years','academic_yearByID']),
      getData(){
          return this.forth_subjects.map(item => ({
              StudentId: item.student_id?this.studentIdByID(item.student_id) :'',
              Name: item.student_id? this.studentNameByID(item.student_id):'',
              Class: item.classes_id?this.classByID(item.classes_id):'',
              Version: item.version_id?this.versionByID(item.version_id):'',
              Wing: item.wing_id?this.wingByID(item.wing_id):'',
              StudentGroup: item.student_group_id?this.student_groupByID(item.student_group_id):'',
              AcademicYear: item.academic_year_id?this.academic_yearByID(item.academic_year_id):'',
          }));
      },
  },
  created() {
    this.$store.dispatch('GET_ALL_FORTH_SUBJECT');
    if(this.classes.length <1) this.$store.dispatch('GET_ALL_CLASSES');
    if(this.versions.length <1) this.$store.dispatch('GET_ALL_VERSION');
    if(this.shifts.length <1) this.$store.dispatch('GET_ALL_SHIFT');
    if(this.wings.length <1) this.$store.dispatch('GET_ALL_WING');
    if(this.student_groups.length <1) this.$store.dispatch('GET_ALL_STUDENT_GROUP');
    if(this.sections.length <1) this.$store.dispatch('GET_ALL_SECTION');
    if(this.students.length <1) this.$store.dispatch('GET_ALL_STUDENT');
    if(this.subjects.length <1) this.$store.dispatch('GET_ALL_SUBJECT');
    if(this.academic_years.length <1) this.$store.dispatch('GET_ALL_ACADEMIC_YEAR');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
